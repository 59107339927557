import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, Params, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { IURLAsDataSource } from 'src/app/interfaces/url.model';
import { BookingService } from 'src/app/services/booking/booking.service';
import { ConfigService } from 'src/app/services/config/config.service';
import { DealerService } from 'src/app/services/dealer/dealer.service';
import { ErrorService } from 'src/app/services/error/error.service';
import { InactivityService } from 'src/app/services/user-account/inactivity/inactivity.service';
import { LoginService } from 'src/app/services/user-account/login/login.service';
import { UserService } from 'src/app/services/user-account/user/user.service';
import { VehicleService } from 'src/app/services/vehicle/vehicle.service';
import { environment } from './../../../../environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.less'],
})
export class LoginComponent implements OnInit, IURLAsDataSource<ActivatedRouteSnapshot> {
  brand: string;
  isRtl: boolean;
  isProd: boolean;
  showActivated: boolean;
  showUnlocked: boolean;
  showReset: boolean;
  passwordResetDone: boolean;
  confirmPasswordValid: boolean;
  invalidLogin: boolean;
  passwordValidationRegex: RegExp;
  passwordValid: boolean;
  showFaq: boolean;
  resendActivation: boolean;
  activationMailSent: boolean;
  showUserManual: boolean;
  isTitan: boolean;
  hasResendActivationConfirmParagraph = false;
  // isAutoline = true;
  model: LoginContract = {
    email: '',
    password: '',
    confirmPassword: '',
  };

deepLinkDealer: string
deepLinkVehicle: any // UrlSegment | string
isNewZealand: boolean
featureReady = true
unlockAccountReady = false

  constructor(
    private readonly loginService: LoginService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly userService: UserService,
    private readonly configService: ConfigService,
    private readonly bookingService: BookingService,
    private readonly vehicleService: VehicleService,
    private readonly errorService: ErrorService,
    private readonly dealerService: DealerService,
    private readonly translationService: TranslateService,
    private readonly inactivityService: InactivityService
  ) {}


  ngOnInit() {


    this.isNewZealand = this.configService.configSettings.country === "New Zealand";


    if (this.route.snapshot.routeConfig.path === "scheduling") {
      this.getDataFromUrl(this.route.snapshot)
    }

    this.extractValuesFromDeepLink()



    const paramMap = this.route.snapshot.queryParams;

    this.showUserManual = this.configService.configSettings.includeUserManual;
    this.showFaq = this.configService.configSettings.includeFaq;
    this.brand = this.configService.brand;
    this.isProd = environment.production;
    this.passwordValidationRegex = new RegExp(
      this.configService.configSettings.formValidation.password
    );
    this.hasResendActivationConfirmParagraph =
      this.configService.configSettings.language.indexOf('sg') > -1;
    if ((paramMap.Reset && paramMap.Token) || paramMap.PasswordReset) {
      if (paramMap.PasswordReset) this.isTitan = true;
      this.showReset = true;
    }
    else if (paramMap.Unlock && paramMap.Token && this.unlockAccountReady) {

      this.userService.unlockUser(paramMap.Token).then(() => {
        this.showUnlocked = true;
        setTimeout(() => {
          this.showUnlocked = false;
          if (
            this.configService.configSettings.nonAutolineDealers ||
            this.configService.configSettings.hasTitanDealers
          ) {

            this.router.navigateByUrl('/select-dealer');
          }
        }, 6000);
      });
        return
      }
    else if (paramMap.Token) {
      this.userService.activateUser(paramMap.Token).then(() => {
        this.showActivated = true;
        setTimeout(() => {
          this.showActivated = false;
          if (
            this.configService.configSettings.nonAutolineDealers ||
            this.configService.configSettings.hasTitanDealers
          ) {

            this.router.navigateByUrl('/select-dealer');
          }
        }, 6000);
      });
    } else if (paramMap.ActivationToken && paramMap.email) {
      this.userService
        .activateUserTitan(
          paramMap.ActivationToken.replace(/\s/g, '+'),
          paramMap.email
        )
        .pipe(take(1))
        .subscribe(() => {
          this.showActivated = true;
          setTimeout(() => {
            this.showActivated = false;
            this.router.navigateByUrl('/select-dealer');
          }, 6000);
        });
    }
    else {
      if (
        (this.configService.configSettings.nonAutolineDealers &&
          !paramMap.doLogin) ||
        (this.configService.configSettings.hasTitanDealers && !paramMap.doLogin)
      ) {
        this.router.navigateByUrl('/select-dealer');
      }
    }

    if (paramMap.language) this.translationService.use(paramMap.language);

    this.translationService.onLangChange.subscribe((result) => {
      this.isRtl = result.lang.includes('ar_');
    });

    // debugger
    // if (this.userService.selectedDealer && this.userService.selectedDealer.TitanDealer) {
    //   this.isAutoline = !this.userService.selectedDealer.TitanDealer
    // }
  }

  getDataFromUrl(routerSnapShot: ActivatedRouteSnapshot) {
    const {VIN, cpn, sab, DEST} = routerSnapShot.queryParams
    const {rooftopId, chassisNo, registrationNumber, recommendedService} = routerSnapShot.queryParams
    const deepLinkRecommendedService = recommendedService.split(":")

sessionStorage.setItem("deepLinkVin", chassisNo);
sessionStorage.setItem("deepRegNum", registrationNumber);
sessionStorage.setItem("deepLinkRecommendedService", JSON.stringify(deepLinkRecommendedService) );
sessionStorage.setItem("deepLinkRooftop", rooftopId);

this.router.navigateByUrl('/')

// sessionStorage.setItem("deepLinkVin", VIN);
// // sessionStorage.setItem("deepLinkRecommendedService", JSON.stringify(sab));
// sessionStorage.setItem("deepLinkRecommendedService", sab);
// sessionStorage.setItem("deepLinkRooftop", DEST);


// https://testservice.bmw.co.nz/scheduling/?&rooftopId=ACBMW10&chassisNo=WABKR6102J0Y32342&registrationNumber=CKH991NW&RecommendedServices=SOLBRKFLUSHDUE:SOLBRKFLUSHOVER:SOLEMMTEST:SOLFRTBRKDUE:SOLMICROF:SOLNOTES:SOLOIL:SOLRRBRKDUE:SOLSERVOVR:SOLVEHTEST:SOLRECALL
// http://localhost:4200/scheduling?rooftopId=ACBMW10&chassisNo=WABKR6102J0Y32342&registrationNumber=CKH991NW&recommendedService=SOLBRKFLUSHDUE:SOLBRKFLUSHOVER:SOLEMMTEST:SOLFRTBRKDUE:SOLMICROF:SOLNOTES:SOLOIL:SOLRRBRKDUE:SOLSERVOVR:SOLVEHTEST:SOLRECALL
  }
  validateConfirmPassword(formEvent: HTMLFormElement) {
    this.confirmPasswordValid =
      formEvent.form.value.resetPassword.length > 0 &&
      formEvent.form.value.resetPassword === this.model.confirmPassword;
  }

  validatePassword(formEvent: HTMLFormElement) {
    this.passwordValid = this.passwordValidationRegex.test(
      formEvent.form.value.resetPassword
    );
  }

  async extractValuesFromDeepLink () {
    if (this.featureReady && this.isNewZealand && this.route.snapshot.url[1] && this.route.snapshot.url[1].path && this.route.snapshot.url[1].path === 'ssl' ) {

      this.route.params.subscribe((param: Params) => {
        if (param['deepLinkValues']) {
          const paramArr = param['deepLinkValues'].split('&')
          const deepLinkRecommendedServiceWithTeleservice = paramArr[7].split(" ")
          const deepLinkRecommendedService = deepLinkRecommendedServiceWithTeleservice[1].split(":")
          const deepLinkRooftop = paramArr[1]
          this.deepLinkDealer = deepLinkRooftop
          this.deepLinkVehicle = paramArr[2]

          sessionStorage.setItem("deepLinkVin", this.deepLinkVehicle);
          sessionStorage.setItem("deepLinkRecommendedService", JSON.stringify(deepLinkRecommendedService));
          sessionStorage.setItem("deepLinkRooftop", deepLinkRooftop);
          this.router.navigateByUrl('/')

          // setTimeout(() => this.router.navigateByUrl('/'), 2000);

        }

      })


    }
  }

  doResetPassword(formEvent: HTMLFormElement) {
    if (this.route.snapshot.queryParams.PasswordReset) {
      this.userService
        .doResetPasswordTitan(
          this.route.snapshot.queryParams.Token.replace(/\s/g, '+'),
          formEvent.form.value.resetPassword,
          this.model.email
        )
        .pipe(take(1))
        .subscribe(() => {
          this.passwordResetDone = true;
          setTimeout(() => {
            this.showReset = false;
            if (
              this.configService.configSettings.nonAutolineDealers ||
              this.configService.configSettings.hasTitanDealers
            ) {
              this.router.navigateByUrl('/select-dealer');
            }
          }, 6000);
        });
    } else {
      this.userService
        .doResetPassword(
          this.route.snapshot.queryParams.Token,
          formEvent.form.value.resetPassword
        )
        .then(() => {
          this.passwordResetDone = true;
          setTimeout(() => {
            this.showReset = false;
            if (
              this.configService.configSettings.nonAutolineDealers ||
              this.configService.configSettings.hasTitanDealers
            ) {
              this.router.navigateByUrl('/select-dealer');
            }
          }, 6000);
        });
    }
  }

  loginAutolineNOTSECURE(backdoor: boolean, callback: Function) {
    this.loginService
      .login(this.model.email, this.model.password)
      .then((result) => {
        if (result) {
          if (this.route.snapshot.queryParams.dealer || this.route.snapshot.url[1].path === "ssl") {
            this.dealerService
              .getDealerList()
              .pipe(take(1))
              .subscribe((result) => {

                this.userService.selectedDealer = result.find(
                  (dealer) =>
                    dealer.RooftopId.trim() ===
                    (this.route.snapshot.queryParams.dealer || this.deepLinkDealer)
                );
                this.inactivityService.setInactivityTimer(25);

                this.userService
                  .getCustomerInformation()
                  .pipe(take(1))
                  .subscribe((userResult) => {
                    if (userResult.Result.ErrorCode === 0) {
                      this.invalidLogin = false;
                      this.userService.userInfo =
                        userResult.CustomerInformationResult;
                      callback();
                    }
                  });
              });
          } else {
            this.inactivityService.setInactivityTimer(25);

            this.userService
              .getCustomerInformation()
              .pipe(take(1))
              .subscribe((userResult) => {
                if (userResult.Result.ErrorCode === 0) {
                  this.invalidLogin = false;
                  this.userService.userInfo =
                    userResult.CustomerInformationResult;
                  callback();
                }
              });
          }
        }
      })
      .catch((error) => {

        if (error.error.Code === '25') {
          this.errorService.show(25);
        }
        this.invalidLogin = true;
      });
  }

  loginAutoline(backdoor: boolean, callback: Function) {
    this.loginService
      .loginSecure(this.model.email, this.model.password)
      .then((result) => {
        if (result) {
          if (this.route.snapshot.queryParams.dealer || (sessionStorage.getItem('deepLinkRooftop') && this.isNewZealand && this.featureReady)) {
            this.deepLinkDealer = sessionStorage.getItem('deepLinkRooftop')
            this.dealerService
              .getDealerList()
              .pipe(take(1))
              .subscribe((result) => {
                this.userService.selectedDealer = result.find(
                  (dealer) =>
                    dealer.RooftopId.trim() ===
                    (this.route.snapshot.queryParams.dealer || this.deepLinkDealer)
                );
                this.inactivityService.setInactivityTimer(25);
                this.userService
                  .getCustomerInformation()
                  .pipe(take(1))
                  .subscribe((userResult) => {
                    if (userResult.Result.ErrorCode === 0) {
                      this.invalidLogin = false;
                      this.userService.userInfo =
                        userResult.CustomerInformationResult;
                      callback();
                    }
                  });
              });
          } else {
            this.inactivityService.setInactivityTimer(25);
            this.userService
              .getCustomerInformation()
              .pipe(take(1))
              .subscribe((userResult) => {
                if (userResult.Result.ErrorCode === 0) {
                  this.invalidLogin = false;
                  this.userService.userInfo =
                    userResult.CustomerInformationResult;
                  callback();
                }
              });
          }
        }
      }).then(() => {
        if (this.configService.configSettings.hasServiceContracts) {
          this.userService.getMachineToken().subscribe((machineAuthResponse) => {
            sessionStorage.setItem("machineToken", machineAuthResponse.access_token)
          })
        }
      })
      .catch((error) => {

        if (error.error.message.Code === '25') {
          this.errorService.show(25);
          return
        }
        if (error === 500 || error === 401) {
          this.invalidLogin = true;
        }

        this.invalidLogin = true;
      });
  }

  login(backdoor?: boolean) {
    const paramMap = this.route.snapshot.queryParams;
    this.bookingService.clearBookingData(paramMap.doLogin || paramMap.dealerId);
    this.vehicleService.vehicleList = [];

    if (
      this.userService.selectedDealer &&
      this.userService.selectedDealer.TitanDealer
    ) {
      this.loginService
        .loginTitan(this.model.email, this.model.password)
        .pipe(take(1))
        .subscribe(
          () => {
            this.inactivityService.setInactivityTimer(25);
            this.loginService
              .getUserInfoTitan(this.model.email)
              .pipe(take(1))
              .subscribe((result) => {
                this.userService.userInfo = {
                  Activated: true,
                  EmailAddress: result.Email,
                  FirstName: result.FirstName,
                  MobileTelNo: result.Mobile,
                  SurName: result.LastName,
                  PreferredRoofTopId: this.userService.selectedDealer.RooftopId,
                  CustomerId: result.Id,
                  Address1: result.StreetAddress
                    ? result.StreetAddress.StreetNo
                    : '',
                  Address2: result.StreetAddress
                    ? result.StreetAddress.StreetLine1
                    : '',
                  Address3: result.StreetAddress
                    ? result.StreetAddress.StreetLine2
                    : '',
                  PostalCode: result.StreetAddress
                    ? result.StreetAddress.PostCode
                    : '',
                  HomeTelNo: result.HomePhone,
                  WorkTelNo: result.WorkPhone,
                };
                this.dealerService
                  .getTitanLocations(
                    parseInt(this.userService.selectedDealer.RooftopId)
                  )
                  .pipe(take(1))
                  .subscribe((location) => {
                    this.userService.selectedLocation = location[0];
                    this.router.navigateByUrl('/select-service');
                  });
              });
          },
          (error) => {
            this.invalidLogin = true;
          }
        );
    } else {
      this.loginAutoline(backdoor, () => {
        if (paramMap.doLogin) {
          this.bookingService
            .createBooking()
            .pipe(take(1))
            .subscribe((result) => {
              this.userService.bookingId = result;
              this.router.navigateByUrl('/select-service');
            });
        } else {
          if ((paramMap.dealer && this.userService.selectedDealer) || (this.userService.selectedDealer && this.deepLinkDealer && this.isNewZealand && this.featureReady)) {
            this.router.navigateByUrl('/select-service');
          } else {
            this.router.navigateByUrl('/select-dealer');
          }
        }
      });
    }
  }

  register() {
    this.router.navigateByUrl('/user-account/register');
  }

  resetPassword() {
    this.router.navigateByUrl('/user-account/reset-password');
  }

  resendActivationMail() {
    this.resendActivation = true;
  }

  doResendActivationMail(formEvent: HTMLFormElement) {
    this.userService
      .requestToken()
      .pipe(take(1))
      .subscribe((token: any) => {
        // debugger
        if (token.errorCode === 0) {
          this.userService.token = token.token;

          this.userService
            .resendActivationMail(formEvent.form.value.resendActivationEmail)
            .pipe(take(1))
            .subscribe(() => {
              this.activationMailSent = true;
              setTimeout(() => (this.resendActivation = false), 6000);
            });
        }
      });
  }
}

class LoginContract {
  email: string;
  password: string;
  confirmPassword: string;
}
