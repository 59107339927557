import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ConfigService } from '../config/config.service';
import { UserService } from '../user-account/user/user.service';
import { DealerModel } from './dealer.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DealerService {
  dealerList: DealerModel[];

  constructor(
    private readonly httpClient: HttpClient,
    private readonly configService: ConfigService,
    private readonly userService: UserService
  ) { }

  getDealerList(): Observable<DealerModel[]> {
    // console.trace()
    const dealerList: DealerModel[] = [];

    if (this.configService.configSettings.isLevelOne) {
      // return this.httpClient.post<any>(`${this.configService.configSettings.serverUrl}${this.configService.configSettings.communityId}/ServiceOnline/GetRooftopList`
      return this.getNonAutolineDealerList().pipe(map(result => {
      // console.log("result", result);

      if (result.length > 0) {
        result.forEach(dealer => {
          dealerList.push(dealer);
        });
      }
      return dealerList;
    }));
    } else {
      return this.httpClient.post<any>(`${this.configService.configSettings.serverUrl}${this.configService.configSettings.communityId}/ServiceOnline/GetRooftopList`
        , new GetDealerListModel(this.userService)
      ).pipe(map(result => {
        if (result.Result.ErrorCode === 0) {
          result.RooftopList.forEach(dealer => {
            dealerList.push(dealer);
          });
        }
        return dealerList;
      }));

    }
  }

  // getTitanTokenOld(): Observable<string> {
  //   const headers: HttpHeaders = new HttpHeaders({ 'Content-Type': `application/x-www-form-urlencoded` });
  //   const body = `grant_type=password&username=${this.configService.configSettings.titanAdminUsername}&password=${this.configService.configSettings.titanAdminPassword}&dealer_id=${this.configService.configSettings.titanCommunity}`;
  //   return this.httpClient.post<any>(`${environment.titanUrl}token`, body, { headers }).pipe(map(result => result.access_token));
  // }

  getTitanToken(): Observable<string> {
    const headers: HttpHeaders = new HttpHeaders({ 'Content-Type': `application/json` });
    const body = {
    grant_type: "password",
    dealer_id: `${this.configService.configSettings.titanCommunity}`,
    communityId: `${this.configService.configSettings.communityId}`,
    partnerId: `${this.configService.configSettings.partnerId}`,
    roofTopId: "BMTHAPTB2B",
    version: `${this.configService.configSettings.version}`
    }

    // return this.httpClient.post<any>(`${environment.solServerUrl}/api/serviceOnline/request-titan-token`, body, { headers }).pipe(map(result => result.access_token));
    return this.httpClient.post<any>(`${environment.solAPIUrl}/api/v1/requestTitanToken`, body, { headers }).pipe(map(result => result.access_token));
  }

  getTitanDealerList(token: string): Observable<DealerModel[]> {
    const dealerList: DealerModel[] = [];
    const headers: HttpHeaders = new HttpHeaders({
      Authorization: `bearer ${token}`
    });
    return this.httpClient.get<any>(`${environment.titanUrl}Dealership`, { headers }).pipe(map(result => {
      result.forEach(dealer => {
        dealerList.push({
          Address1: dealer.Address,
          EmailAddress: dealer.Email,
          RooftopId: dealer.DealerId,
          DealershipName: dealer.DealerName,
          SOLEnabled: false,
          Telephone: dealer.PhoneNumber,
          TitanDealer: true
        });
      });
      return dealerList;
    }));
  }

  getTitanLocations(dealerId: number): Observable<LocationModelTitan[]> {
    return this.httpClient.get<LocationModelTitan[]>(`${environment.titanUrl}Booking/Locations?dealershipId=${dealerId}`);
  }

  getNonAutolineDealerList(): Observable<DealerModel[]> {
    //debugger
    return this.httpClient.get<DealerModel[]>(`${environment.serviceOnlineService}/assets/dealerInfo/${this.configService.configSettings.communityId}.json`);
  }
}

class LocationModelTitan {
  Id: number;
  LocationCode: string;
  LocationName: string;
  LocationType: string;
  BookingStartTime: Date;
  BookingEndTime: Date;
  BookingInterval: number;
  StatusCode: number;
  Email: string;
  PhoneNumber: string;
  StreetNumber: string;
  StreetLine1: string;
  StreetLine2: string;
  PostCode: string;
  Suburb: string;
  SubDistrict: string;
  State: string;
  CountryCode: number;
  Longitude: number;
  Latitude: number;
  StopBookingAtUsageRate: number;
  LeadTimeInDays: number;
  CreationUserName: string;
  CreationTimestamp: Date;
  LastUpdateUserName: string;
  LastUpdateTimestamp: Date;
}

// TODO: refactor username
class GetDealerListModel {
  constructor(userService: UserService) {
    this.CustomerId = userService.customerId || "henry.barker@partner.bmw.co.za";
    this.RooftopType = 0;
  }
  CustomerId: string;
  RooftopType: number;
  Token: string;


}
