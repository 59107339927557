import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { map } from "rxjs/operators";
import { DateFormatter } from "src/app/helpers/date-format.helper";
import { environment } from "src/environments/environment";
import { ConfigService } from "../config/config.service";
import { TitanCustomerModel } from "../user-account/login/login.service";
import { UserService } from "../user-account/user/user.service";
import { Model } from "../vehicle/model.model";
import { BookingEmailModel } from "./booking-mail.model";
import { BookingModel, JobModel } from "./booking.model";

@Injectable({
  providedIn: "root",
})
export class BookingService {
  resetVehicle = new Subject<boolean>();
  private readonly _bookingDeleted = new BehaviorSubject<number>(undefined);
  readonly bookingDeleted$ = this._bookingDeleted.asObservable();

  get bookingDeleted(): number {
    return this._bookingDeleted.getValue();
  }

  set bookingDeleted(value: number) {
    if (value) this._bookingDeleted.next(value);
  }

  private readonly _isLevelOneBooking = new BehaviorSubject<boolean>(false);
  readonly isLevelOneBooking$ = this._isLevelOneBooking.asObservable();

  get isLevelOneBooking(): boolean {
    return this._isLevelOneBooking.getValue();
  }

  set isLevelOneBooking(value: boolean) {
    if (value) this._isLevelOneBooking.next(value);
  }

  constructor(
    private readonly httpClient: HttpClient,
    private readonly configService: ConfigService,
    private readonly userService: UserService,
    private readonly translationService: TranslateService
  ) {}

  createBooking(): Observable<number> {
    return this.httpClient
      .post<any>(
        `${this.configService.configSettings.serverUrl}${this.configService.configSettings.communityId}/ServiceOnline/AddAppointment`,
        new CreateBookingContract(this.userService)
      )
      .pipe(
        map((result) => {
          if (result.Result.ErrorCode === 0) {
            return result.AppointmentId;
          }
          return 0;
        })
      );
  }

  updateBooking(): Observable<boolean> {

    return this.httpClient
      .post<any>(
        `${this.configService.configSettings.serverUrl}${this.configService.configSettings.communityId}/ServiceOnline/UpdateAppointment`,
        new UpdateBookingContract(this.userService)
      )
      .pipe(
        map((result) => {
          if (
            this.configService.configSettings.isCRM &&
            result.ErrorCode === 0
          ) {
            // console.log(
            //   sessionStorage.getItem("editBookingTouchPoint") === "03"
            // );
            let touchPoint = !!sessionStorage.getItem("editBookingTouchPoint")
              ? "O3"
              : "O4";
            // console.log(touchPoint);

            this.sendEmailForZaBookingActionType(touchPoint);
            return true;
          }
          if (result.ErrorCode === 0) {
            return true;
          }
          return false;
        })
      );
  }

  sendEmailForZaBookingActionType(touchPoint) {
    let selectedServiceAdvisorOption = "any advisor";
    const bpNo = sessionStorage.getItem("BpNo");
    // console.log("--> bp", bpNo);

    if (
      this.userService.serviceAdvisor &&
      this.userService.serviceAdvisor.advisorName
    ) {
      selectedServiceAdvisorOption =
        this.userService.serviceAdvisor.advisorName;
    }


// console.log("userSer-->", this.userService);

    let bodyNo = "unknown";
    const removeTimeFromDate =  new Date(this.userService.selectedServiceDate);

    if (this.userService.selectedVehicle.chassisNo) {
      bodyNo = this.userService.selectedVehicle.chassisNo;
    }

    const reqBody = {
      touchPoint,
      bpNo, //||'0010107828',
      email: this.userService.customerId,
      bodyNo,
      regNumber: this.userService.selectedVehicle.registrationNumber, //'333DLMGP',
      // jobs: ['Front Brakes pads Due', 'hgvhhjh'],
      jobs: this.userService.selectedServices.map(
        (serviceJob) => serviceJob.jobDescription
      ),
      referenceNumber: 20878,
      bookingDate:  `${removeTimeFromDate.getFullYear()}-${removeTimeFromDate.getMonth() + 1}-${removeTimeFromDate.getDate()}`, //'2022-09-30',
      bookingTime: `${this.userService.selectedServiceSlot.split('-')[0]}`,
      preferredDealer: this.userService.selectedDealer.DealershipName,
      serviceAdvisor: selectedServiceAdvisorOption,

      dealerAddress: `${this.userService.selectedDealer.Address1} ${this.userService.selectedDealer.Address2}`,
      dealerCity: "",
      dealerContact: this.userService.selectedDealer.Telephone,
      dealerEmail: this.userService.selectedDealer.EmailAddress,
      transportMethod: this.userService.selectedServiceMobilityOption,
      transportMethodOut: this.userService.selectedServiceMobilityOption === "STAN" ? "Appointment without Shuttle" : "Appointment with Shuttle" ,//,
      transAddress1: this.userService.selectedServiceMobilityOptionAddress,
      transAddress2: null,
      transAddress3: null,
      transAddress4: null,
      transAddress5: null,
      transAddress6: null,
      secondTransAddress1: this.userService.selectedServiceMobilityDeliveryOptionAddress,
      secondTransAddress2: null,
      secondTransAddress3: null,
      secondTransAddress4: null,
      secondTransAddress5: null,
      secondTransAddress6: null,
    };
    const serviceItemsWithNotes = ["No Service Notes"];
    //   const itemsToBeServiced = this.userService.selectedServices.map((serviceJob) => serviceJob.jobDescription)
    this.userService.selectedServices.forEach((serviceJob) => {
      serviceItemsWithNotes.push(serviceJob.jobDescription);
    });
    // console.log('Serv-->', serviceItemsWithNotes);

    //   const serviceItemsWithNotes = [...myArr, "No Service Notes"];
    sessionStorage.removeItem("editBookingTouchPoint");
    return this.httpClient
      .post<any>(
        `${environment.solZAAPIUrl}/api/v1/sendBookingEmail`,

        reqBody
      )
      .subscribe((result) => {
        // debugger
        sessionStorage.removeItem("editBookingTouchPoint");
        // console.log('REsso-->', result);

        return true;
      });
  }

  submitLevelOneBooking(model: LevelOneBookingModel) {
    let subject: string;
    this.translationService
      .get("label_na_form_subjectline")
      .subscribe((result) => {
        subject = result;
      });
    const serviceItems: string[] = model.serviceItems;

    const body: BookingEmailModel = {
      to: environment.production
        ? model.email
        : "henry.barker@partner.bmw.co.za",
      subject: subject,
      firstName: model.firstName,
      lastName: model.surname,
      title: model.title,
      mobileTelNo: model.mobile,
      emailAddress: model.email,
      registration: model.registration,
      model: model.model,
      variant: model.model,
      modelYear: model.modelYear,
      prefDate: model.serviceDate,
      prefTime: model.timeSlot,
      comments: model.notes,
      prefService: serviceItems,
      dealerId: this.userService.selectedDealer.RooftopId,
      dealerName: this.userService.selectedDealer.DealershipName,
      dealerEmail: environment.production
        ? this.userService.selectedDealer.EmailAddress
        : "henry.barker@partner.bmw.co.za",
      dealerPhone: this.userService.selectedDealer.Telephone,
      dealerAddress1: this.userService.selectedDealer.Address1,
      dealerAddress2: this.userService.selectedDealer.Address2,
      dealerAddress3: this.userService.selectedDealer.Address3,
      dealerAddress4: this.userService.selectedDealer.Address4,
      dealerAddress5: this.userService.selectedDealer.Address5,
      emailTemplate: `${this.configService.brand}-email`,
      allModelsUrl: this.configService.configSettings.allModelsUrl,
      facebookUrl: this.configService.configSettings.facebookUrl,
      twitterUrl: "",
      instagramUrl: this.configService.configSettings.instagramUrl,
      youTubeUrl: this.configService.configSettings.youtubeUrl,
      legalUrl: "",
      country: this.configService.configSettings.country,
      language: this.userService.languageSelected.substring(0, 2),
    };
    return this.httpClient.post(`${environment.MailerUrl}`, body, {});
  }

  sendTechnicalCampaignMail(): Observable<any> {
    let subject: string;
    this.translationService
      .get("label_na_form_subjectline")
      .subscribe((result) => {
        subject = result;
      });
    const serviceItems: string[] = [];
    this.userService.selectedServices.forEach((x) =>
      serviceItems.push(x.jobDescription)
    );
    const body: BookingEmailModel = {
      to: environment.production
        ? this.userService.userInfo.EmailAddress
        : "henry.barker@partner.bmw.co.za",
      subject: subject,
      firstName: this.userService.userInfo.FirstName,
      lastName: this.userService.userInfo.SurName,
      title: this.userService.userInfo.Title,
      mobileTelNo: this.userService.userInfo.MobileTelNo.toString(),
      emailAddress: this.userService.userInfo.EmailAddress,
      registration: this.userService.selectedVehicle.registrationNumber,
      model: this.userService.selectedVehicle.modelName,
      variant: this.userService.selectedVehicle.variantCode,
      modelYear: this.userService.selectedVehicle.modelYear,
      prefDate: this.userService.selectedServiceDate,
      prefTime: this.userService.selectedServiceSlot,
      comments:
        this.userService.serviceNotes !== "undefined"
          ? this.userService.serviceNotes
          : "",
      prefService: serviceItems,
      dealerId: this.userService.selectedDealer.RooftopId,
      dealerName: this.userService.selectedDealer.DealershipName,
      dealerEmail: environment.production
        ? this.userService.selectedDealer.EmailAddress
        : "henry.barker@partner.bmw.co.za",
      dealerPhone: this.userService.selectedDealer.Telephone,
      dealerAddress1: this.userService.selectedDealer.Address1,
      dealerAddress2: this.userService.selectedDealer.Address2,
      dealerAddress3: this.userService.selectedDealer.Address3,
      dealerAddress4: this.userService.selectedDealer.Address4,
      dealerAddress5: this.userService.selectedDealer.Address5,
      emailTemplate: `${this.configService.brand}-technical-campaign-mail`,
      allModelsUrl: this.configService.configSettings.allModelsUrl,
      facebookUrl: this.configService.configSettings.facebookUrl,
      twitterUrl: "",
      instagramUrl: this.configService.configSettings.instagramUrl,
      youTubeUrl: this.configService.configSettings.youtubeUrl,
      legalUrl: "",
      country: this.configService.configSettings.country,
      language: this.userService.languageSelected.substring(0, 2),
    };
    return this.httpClient.post(`${environment.MailerUrl}`, body, {});
  }

  confirmLevelOneBooking(): Observable<number> {
    const levelOneBookingContract = new LevelOneBookingContract(
      this.userService
    );
    return this.httpClient.post<number>(
      `${environment.LevelOneApiUrl}addBooking`,
      levelOneBookingContract
    );
  }

  confirmBooking(): Observable<number> | undefined {
    // console.log("userServi", this.userService);

    return this.httpClient
      .post<any>(
        `${this.configService.configSettings.serverUrl}${this.configService.configSettings.communityId}/ServiceOnline/ConfirmAppointment`,
        new ConfirmBookingContract(this.userService)
      )
      .pipe(
        map((result) => {
          if (result.Result.ErrorCode === 0) {
            this.userService.appointmentIdToUpdate = null;
            this.userService.wipNumberToUpdate = null;
            return result.WipNo;
          }
          return undefined;
        })
      );
  }

  makeBookingTitan(model: BookingModelTitan): Observable<any> {
    return this.httpClient.post(`${environment.titanUrl}Booking`, model);
  }

  deleteBooking(
    wipNumber: number,
    appointmentId: number,
    rooftopId: string
  ): Observable<boolean> {
    return this.httpClient
      .post<any>(
        `${this.configService.configSettings.serverUrl}${this.configService.configSettings.communityId}/ServiceOnline/DeleteCustomerAppointment`,
        new DeleteCustomerAppointmentContract(
          this.userService,
          wipNumber,
          appointmentId,
          rooftopId
        )
      )
      .pipe(
        map((result) => {
          if (
            result.Result.ErrorCode === 0 &&
            this.configService.configSettings.isCRM
          ) {
            this.bookingDeleted = appointmentId;
            this.sendEmailForZaBookingActionType("O2");
            // this.sendEmailForZaBookingActionType("O3")
            return true;
          } else if (result.Result.ErrorCode === 0) {
            this.bookingDeleted = appointmentId;
            return true;
          }
          return false;
        })
      );
  }

  deleteBookingTitan(bookingId: number): Observable<boolean> {
    const model = {
      ServiceBookingId: bookingId,
      CancellationCode: 4,
      CancellationComment: "Deleted from SOL",
    };

    return this.httpClient
      .post(`${environment.titanUrl}Booking/CancelBooking`, model)
      .pipe(
        map(() => {
          this.bookingDeleted = bookingId;
          return true;
        })
      );
  }

  deleteLevelOneBooking(wipNumber: number): Observable<boolean> {
    return this.httpClient
      .delete<boolean>(
        `${environment.LevelOneApiUrl}deleteBooking?bookingId=${wipNumber}`
      )
      .pipe(
        map((result) => {
          if (result) this.bookingDeleted = wipNumber;
          return result;
        })
      );
  }

  getListOfAppointments(
    vehicleId: number
  ): Observable<BookingModel> | undefined {
    return this.httpClient.post<BookingModel>(
      `${this.configService.configSettings.serverUrl}${this.configService.configSettings.communityId}/ServiceOnline/GetListOfAppointments`,
      new GetListOfAppointmentsContract(this.userService, vehicleId)
    );
  }

  getListOfAppointmentsTitan(
    customerId: number
  ): Observable<BookingModel> | undefined {
    return this.httpClient
      .get<TitanBookingsResult[]>(
        `${environment.titanUrl}Booking?customerId=${customerId}`
      )
      .pipe(
        map((result) => {
          const bookings: BookingModel = {
            Result: {
              ErrorCode: 0,
            },
            CustomerAppointments: [],
          };
          result.forEach((item) => {
            const jobs: JobModel[] = [];
            item.Jobs.forEach((job) => {
              jobs.push({
                JobCode: job.ServiceOperationCode.Id,
                JobComments: "",
                JobDescription: job.Description,
                JobPrice: 0,
                ParentJobCode: "",
                ProductCode: job.ServiceOperationCode.Id.toString(),
                VHCId: "",
                JobLineId: job.JobLineId,
              });
            });
            if (item.StatusCode !== 2) {
              bookings.CustomerAppointments.push({
                AdvisorDropOffTimeCode:
                  item.Appointment.DropOffTime.toString().substring(11, 16),
                AdvisorId: item.ServiceAdvisorId.toString(),
                FirstAppAdvisorName: `${item.ServiceAdvisor.FirstName} ${item.ServiceAdvisor.LastName}`,
                AppointmentId: item.Id,
                WIPNo: item.Id,
                CanBeAmended: true,
                CanBeDeleted: true,
                RoofTopId:
                  item.Jobs[0].ServiceOperationCode.DealershipId.toString(),
                JobDate: item.Appointment.DropOffTime.toString(),
                FirstAppTime: item.Appointment.DropOffTime.toString(),
                Jobs: jobs,
                VehicleChassisNo: item.Vehicle.Vin,
                VehicleRegistrationNumber: item.Vehicle.RegoNo,
              });
            }
          });
          return bookings;
        })
      );
  }

  reserveTimeSlot(model: TimeSlotReservation): Observable<any> {
    // TODO - fix add vehicle
    return this.httpClient.post(
      `${environment.titanUrl}Booking/TimeslotReservation`,
      model,
      { responseType: "text" }
    );
  }

  clearBookingData(keepDealer?: boolean) {
    if (!keepDealer) this.userService.selectedDealer = undefined;
    this.userService.selectedServiceDate = undefined;
    this.userService.selectedServiceSlot = undefined;
    this.userService.selectedVehicle = undefined;
    this.userService.serviceAdvisor = undefined;
    this.userService.selectedServices = undefined;
    this.userService.serviceNotes = undefined;
    this.userService.appointmentIdToUpdate = undefined;
    this.userService.bookingId = undefined;
    this.userService.reservationToken = undefined;
    this.resetVehicle.next(true);
  }

  getLevelOneBookingList(vehicleId: number): Observable<BookingModel> {
    const bookingList: BookingModel = new BookingModel();
    bookingList.CustomerAppointments = [];
    bookingList.Result = {
      ErrorCode: 0,
    };
    return this.httpClient
      .get<any>(
        `${environment.LevelOneApiUrl}getBookingList?vehicleId=${vehicleId}`
      )
      .pipe(
        map((result) => {
          result.forEach((booking) => {
            const jobModel: JobModel[] = [];
            booking.jobs.split(",").forEach((job) => {
              jobModel.push({
                JobCode: job,
                JobComments: "",
                JobDescription: "",
                JobPrice: 10,
                ParentJobCode: "",
                ProductCode: "",
                VHCId: "",
              });
            });

            booking.jobs.split(",");
            bookingList.CustomerAppointments.push({
              AdvisorDropOffTimeCode: booking.dropOffTimeCode,
              AdvisorId: "",
              AppointmentId: booking.bookingId,
              CanBeAmended: false,
              CanBeDeleted: true,
              JobDate: booking.jobDate,
              Jobs: jobModel,
              RoofTopId: booking.rooftopId,
              WIPNo: booking.bookingId,
            });
          });
          return bookingList;
        })
      );
  }

  getStaticModelList(): Model[] {
    return [
      {
        modelName: "1 Series",
        modelCode: "1 Series",
        variantName: "1 Series",
        variantCode: "1 Series",
        makeCode: "B",
        makeName: "BMW",
      },
      {
        modelName: "2 Series",
        modelCode: "2 Series",
        variantName: "2 Series",
        variantCode: "2 Series",
        makeCode: "B",
        makeName: "BMW",
      },
      {
        modelName: "3 Series",
        modelCode: "3 Series",
        variantName: "3 Series",
        variantCode: "3 Series",
        makeCode: "B",
        makeName: "BMW",
      },
      {
        modelName: "4 Series",
        modelCode: "4 Series",
        variantName: "4 Series",
        variantCode: "4 Series",
        makeCode: "B",
        makeName: "BMW",
      },
      {
        modelName: "5 Series",
        modelCode: "5 Series",
        variantName: "5 Series",
        variantCode: "5 Series",
        makeCode: "B",
        makeName: "BMW",
      },
      {
        modelName: "6 Series",
        modelCode: "6 Series",
        variantName: "6 Series",
        variantCode: "6 Series",
        makeCode: "B",
        makeName: "BMW",
      },
      {
        modelName: "7 Series",
        modelCode: "7 Series",
        variantName: "7 Series",
        variantCode: "7 Series",
        makeCode: "B",
        makeName: "BMW",
      },
      {
        modelName: "8 Series",
        modelCode: "8 Series",
        variantName: "8 Series",
        variantCode: "8 Series",
        makeCode: "B",
        makeName: "BMW",
      },
      {
        modelName: "M2",
        modelCode: "M2",
        variantName: "M2",
        variantCode: "M2",
        makeCode: "B",
        makeName: "BMW",
      },
      {
        modelName: "M3",
        modelCode: "M3",
        variantName: "M3",
        variantCode: "M3",
        makeCode: "B",
        makeName: "BMW",
      },
      {
        modelName: "M4",
        modelCode: "M4",
        variantName: "M4",
        variantCode: "M4",
        makeCode: "B",
        makeName: "BMW",
      },
      {
        modelName: "M5",
        modelCode: "M5",
        variantName: "M5",
        variantCode: "M5",
        makeCode: "B",
        makeName: "BMW",
      },
      {
        modelName: "M6",
        modelCode: "M6",
        variantName: "M6",
        variantCode: "M6",
        makeCode: "B",
        makeName: "BMW",
      },
      {
        modelName: "M8",
        modelCode: "M8",
        variantName: "M8",
        variantCode: "M8",
        makeCode: "B",
        makeName: "BMW",
      },
      {
        modelName: "X1",
        modelCode: "X1",
        variantName: "X1",
        variantCode: "X1",
        makeCode: "B",
        makeName: "BMW",
      },
      {
        modelName: "X2",
        modelCode: "X2",
        variantName: "X2",
        variantCode: "X2",
        makeCode: "B",
        makeName: "BMW",
      },
      {
        modelName: "X3",
        modelCode: "X3",
        variantName: "X3",
        variantCode: "X3",
        makeCode: "B",
        makeName: "BMW",
      },
      {
        modelName: "X4",
        modelCode: "X4",
        variantName: "X4",
        variantCode: "X4",
        makeCode: "B",
        makeName: "BMW",
      },
      {
        modelName: "X5",
        modelCode: "X5",
        variantName: "X5",
        variantCode: "X5",
        makeCode: "B",
        makeName: "BMW",
      },
      {
        modelName: "X6",
        modelCode: "X6",
        variantName: "X6",
        variantCode: "X6",
        makeCode: "B",
        makeName: "BMW",
      },
      {
        modelName: "X7",
        modelCode: "X7",
        variantName: "X7",
        variantCode: "X7",
        makeCode: "B",
        makeName: "BMW",
      },
      {
        modelName: "Z3",
        modelCode: "Z3",
        variantName: "Z3",
        variantCode: "Z3",
        makeCode: "B",
        makeName: "BMW",
      },
      {
        modelName: "Z4",
        modelCode: "Z4",
        variantName: "Z4",
        variantCode: "Z4",
        makeCode: "B",
        makeName: "BMW",
      },
      {
        modelName: "Other",
        modelCode: "Other",
        variantName: "Other",
        variantCode: "Other",
        makeCode: "B",
        makeName: "BMW",
      },
      {
        modelName: "i3",
        modelCode: "i3",
        variantName: "i3",
        variantCode: "i3",
        makeCode: "E",
        makeName: "BMW",
      },
      {
        modelName: "i8",
        modelCode: "i8",
        variantName: "i8",
        variantCode: "i8",
        makeCode: "E",
        makeName: "BMW",
      },
      {
        modelName: "MINI Cooper Clubman",
        modelCode: "MINI Cooper Clubman",
        variantName: "MINI Cooper Clubman",
        variantCode: "MINI Cooper Clubman",
        makeCode: "M",
        makeName: "MINI",
      },
      {
        modelName: "MINI Cooper Countryman",
        modelCode: "MINI Cooper Countryman",
        variantName: "MINI Cooper Countryman",
        variantCode: "MINI Cooper Countryman",
        makeCode: "M",
        makeName: "MINI",
      },
      {
        modelName: "MINI Cooper D Countryman",
        modelCode: "MINI Cooper D Countryman",
        variantName: "MINI Cooper D Countryman",
        variantCode: "MINI Cooper D Countryman",
        makeCode: "M",
        makeName: "MINI",
      },
      {
        modelName: "MINI Cooper S Clubman",
        modelCode: "MINI Cooper S Clubman",
        variantName: "MINI Cooper S Clubman",
        variantCode: "MINI Cooper S Clubman",
        makeCode: "M",
        makeName: "MINI",
      },
      {
        modelName: "MINI Cooper S Countryman",
        modelCode: "MINI Cooper S Countryman",
        variantName: "MINI Cooper S Countryman",
        variantCode: "MINI Cooper S Countryman",
        makeCode: "M",
        makeName: "MINI",
      },
      {
        modelName: "MINI John Cooper Works ALL4 Countryman",
        modelCode: "MINI John Cooper Works ALL4 Countryman",
        variantName: "MINI John Cooper Works ALL4 Countryman",
        variantCode: "MINI John Cooper Works ALL4 Countryman",
        makeCode: "M",
        makeName: "MINI",
      },
      {
        modelName: "MINI John Cooper Works Clubman",
        modelCode: "MINI John Cooper Works Clubman",
        variantName: "MINI John Cooper Works Clubman",
        variantCode: "MINI John Cooper Works Clubman",
        makeCode: "M",
        makeName: "MINI",
      },
      {
        modelName: "Other",
        modelCode: "Other",
        variantName: "Other",
        variantCode: "Other",
        makeCode: "M",
        makeName: "MINI",
      },
    ];
  }
}

export interface BookingModelTitan {
  Booking: BookingTitan;
}

interface BookingTitan {
  Id?: number;
  LocationId: number;
  VehicleId: number;
  CustomerId: number;
  AppointmentId?: number;
  StatusCode?: number;
  BookingSource?: number;
  CustomerRelationship?: number;
  ServiceAdvisorId: number;
  DmsBookingId?: string;
  DmsBookingKey?: number;
  CreationUserName?: string;
  CreationTimestamp?: Date;
  LastUpdateUserName?: string;
  IsSendConfirmationBooking: boolean;
  LastUpdateTimestamp?: Date;
  ReservationToken: string;
  IsSendConfirmEmail: boolean;
  Customer?: CustomerModelTitan;
  Vehicle?: VehicleModelTitan;
  Jobs: TitanJobModel[];
  ServiceAdvisor: ServiceAdvisorModelTitan;
  IsFromDMS: boolean;
}

interface TitanBookingsResult {
  Id: number;
  LocationId: number;
  VehicleId: number;
  CustomerId: number;
  AppointmentId: number;
  StatusCode: number;
  BookingSource: any;
  CustomerRelationship: any;
  ServiceAdvisorId: number;
  DmsBookingId: number;
  DmsBookingKey: number;
  CreationUserName: string;
  CreationTimestamp: Date;
  LastUpdateUserName: string;
  LastUpdateTimestamp: Date;
  ReservationToken: string;
  IsSendConfirmEmail: boolean;
  Customer: CustomerModelTitan;
  Vehicle: VehicleModelTitan;
  Appointment: AppointmentTitan;
  Jobs: TitanJobModel[];
  ServiceAdvisor: ServiceAdvisorModelTitan;
}

interface AppointmentTitan {
  Id: number;
  LocationId: number;
  DmsLocationKey: number;
  BookingDate: Date;
  DropOffTime: Date;
  PickUpTime: Date;
  CreationUserName: string;
  CreationTimestamp: Date;
  LastUpdateUserName: string;
  LastUpdateTimestamp: Date;
}

interface ServiceAdvisorModelTitan {
  Id: number;
  LocationId: number;
  StatusCode?: number;
  Name?: string;
  FirstName?: string;
  LastName?: string;
  MiddleName?: string;
  DmsUserId?: string;
}

interface ServiceOperationCode {
  Id: number;
  DealershipId: number;
  ServiceType: number;
  Name: string;
  StatusCode: number;
  DmsCodebookSourceCode: number;
  ServiceCode: string;
  DmsOperationCode: string;
  StandardPrice: number;
  StandardHour: number;
  SectionCode: number;
  ClassCode: number;
  Description: string;
  ServiceMaintenanceKms: number;
  ServiceMaintenanceMonth: number;
  MakeId: number;
}

export interface TitanJobModel {
  Id?: number;
  ServiceBookingId?: number;
  ServiceOperationCodeId: number;
  Description?: string;
  Price?: number;
  Duration?: number;
  DmsRepairOrderJobKey?: number;
  DmsOperationCode?: string;
  DmsJobLineId?: number;
  JobLineId?: number;
  ServiceOperationCode?: ServiceOperationCode;
  ServiceOperationCodeServiceCode?: string;
}

interface VehicleModelTitan {
  Id: number;
  DealershipId: number;
  StatusCode?: number;
  Vin: string;
  RegoNo: string;
  RegoBureaucracy?: string;
  Description?: string;
  MakeId: number;
  ModelId: number;
  Colour?: string;
  ManufactureDate?: Date;
  ComplianceDate?: Date;
  ManufactureId: string;
  Family?: string;
  OwnerCustomerId: number;
  VehicleTransmission?: string;
  FuelCode?: number;
  DriveType?: number;
  VehicleEngine?: string;
  InteriorColour?: string;
  OdometerReading?: number;
  SellingDealerCode?: string;
  CreationUserName?: string;
  CreationTimestamp?: Date;
  LastUpdateUserName?: string;
  LastUpdateTimestamp?: Date;
  DmsVehicleKey?: number;
  OwnerCustomer?: TitanCustomerModel;
}

interface AddressTitan {
  StreetNo: string;
  AddressType: number;
  StreetLine1: string;
  StreetLine2: string;
  Suburb: string;
  State: string;
  PostCode: string;
  CountryCode: number;
  SubDistrict: string;
}

export interface CustomerModelTitan {
  Id: number;
  DealershipId: number;
  StatusCode?: number;
  Name?: string;
  FirstName?: string;
  MiddleName?: string;
  LastName?: string;
  Email?: string;
  ThirdPartyCustomerId?: string;
  Note?: string;
  HomePhone?: string;
  Mobile?: string;
  WorkPhone?: string;
  DmsCustomerKey?: number;
  StreetAddress?: AddressTitan;
  PostalAddress?: AddressTitan;
  IsOrganisation: boolean;
  IsSendConfirmEmail: boolean;
}

export interface TimeSlotReservation {
  LocationId: number;
  BookingDate: string;
  DropOffTime: string;
  PickUpTime: string;
  ReservationToken?: string;
}

export interface LevelOneBookingModel {
  title: string;
  firstName: string;
  surname: string;
  email: string;
  mobile: string;
  registration: string;
  modelYear: string;
  serviceDate: Date;
  notes: string;
  serviceItems: string[];
  model: string;
  timeSlot: string;
}

export interface ServiceItems {
  description: string;
  code: string;
  selected?: boolean;
}

class LevelOneBookingContract {
  constructor(userService: UserService) {
    this.VehicleId = userService.selectedVehicle.vehicleId;
    this.RooftopId = userService.selectedDealer.RooftopId;
    this.DropOffTimeCode = userService.selectedServiceSlot;
    this.JobDate = DateFormatter.formatDate(
      new Date(userService.selectedServiceDate)
    );
    userService.selectedServices.forEach((service) => {
      this.Jobs.push({
        JobCode: service.jobCode.toString(),
        JobDescription: service.jobDescription,
      });
    });
  }
  id: number;
  VehicleId: number;
  DropOffTimeCode: string;
  JobDate: string;
  Jobs: Job[] = [];
  RooftopId: string;
}

class DeleteCustomerAppointmentContract {
  constructor(
    userService: UserService,
    wipNumber: number,
    appointmentId: number,
    rooftopId: string
  ) {
    this.WIPNo = wipNumber;
    this.RooftopId = rooftopId;
    this.CustomerId = userService.customerId;
    this.AppointmentId = appointmentId;
    this.InformCustomer = true;
    if (userService.languageSelected === "ja-JP") {
      this.Language = userService.languageSelected;
    } else {
      this.Language = userService.languageSelected.substring(0, 2);
    }
  }
  AppointmentId: number;
  CustomerId: string;
  InformCustomer: boolean;
  Language: string;
  RooftopId: string;
  WIPNo: number;
}

class GetListOfAppointmentsContract {
  constructor(userService: UserService, vehicleId: number) {
    this.CustomerId = userService.customerId;
    this.RooftopId = userService.selectedDealer.RooftopId;
    this.VehicleId = vehicleId;
    this.Language = userService.languageSelected.substring(0, 2);
  }
  AdvisorId: string = "";
  AppointmentId: string = "";
  ChassisNo: string = "";
  CustomerId: string;
  GetCommunityAppointments: boolean = true;
  Language: string;
  LastStep: string = "";
  RegNumber: string = "";
  RooftopId: string;
  VehicleId: number;
  WipNo: string = "";
}

class CreateBookingContract {
  constructor(userService: UserService) {
    // debugger
    this.RooftopId = userService.selectedDealer.RooftopId;
    this.CustomerId = userService.customerId;
    if (userService.appointmentIdToUpdate)
      this.ParentAppointmentId = userService.appointmentIdToUpdate;
  }
  RooftopId: string;
  CustomerId: string;
  ParentAppointmentId: number;
}
class Job {
  JobCode: string;
  JobDescription: string;
}

class JobCode {
  JobCode: string;
  JobDescription: string;
  JobExtDescription: string;
  JobPrice: number;
  JobTime: string;
  JobTypeCode: string;
  ParentJobCode: string;
  ProductCode: string;
  JobComments?: string;
}

class UpdateBookingContract {
  constructor(userService: UserService) {
    this.RooftopId = userService.selectedDealer.RooftopId;
    this.CustomerId = userService.customerId;
    this.EmailAddress = userService.customerId;
    this.AppointmentId = userService.bookingId;
    this.LastStep = 4;
    this.JobDate = DateFormatter.formatDate(
      new Date(userService.selectedServiceDate)
    );
    this.Jobs = [];
    userService.selectedServices.forEach((service) => {
      this.Jobs.push({
        JobCode: service.jobCode.toString(),
        JobDescription: service.jobDescription,
        JobExtDescription: service.jobExtDescription,
        JobPrice: service.jobPrice,
        JobTime: service.jobTime,
        JobTypeCode: service.jobTypeCode,
        ParentJobCode: service.parentJobCode,
        ProductCode: service.productCode,
      });
    });
    if (userService.serviceNotes && userService.serviceNotes !== "undefined") {
      userService.serviceNotesItem.jobComments = userService.serviceNotes;
      this.Jobs.push({
        JobCode: userService.serviceNotesItem.jobCode.toString(),
        JobDescription: userService.serviceNotesItem.jobDescription,
        JobPrice: userService.serviceNotesItem.jobPrice,
        ParentJobCode: userService.serviceNotesItem.parentJobCode,
        ProductCode: userService.serviceNotesItem.productCode,
        JobExtDescription: userService.serviceNotesItem.jobExtDescription,
        JobTime: userService.serviceNotesItem.jobTime,
        JobTypeCode: userService.serviceNotesItem.jobTypeCode,
        JobComments: userService.serviceNotesItem.jobComments,
      });
    }
    this.ParentAppointmentId = userService.appointmentIdToUpdate
      ? userService.appointmentIdToUpdate
      : null;
    // TODO: REFACTOR
    this.Language =
      userService.languageSelected === "en_ae"
        ? userService.languageSelected.substring(0, 2) + "-ZA"
        : userService.languageSelected.substring(0, 2);
    this.AdvisorID = userService.serviceAdvisor
      ? userService.serviceAdvisor.advisorID
      : null;
    this.AdvisorDropOffTimeCode = userService.selectedServiceSlot;
    this.TransportMethod = userService.selectedServiceMobilityOption;
    this.VehColour = userService.selectedVehicle.colour;
    this.VehMakeCode = userService.selectedVehicle.makeCode;
    this.VehMakeDesc = userService.selectedVehicle.makeName;
    this.VehModelCode = userService.selectedVehicle.modelCode;
    this.VehModelDesc = userService.selectedVehicle.modelName;
    this.VehTrim = userService.selectedVehicle.trim;
    this.VehVariantCode = userService.selectedVehicle.variantCode;
    this.VehVariantDesc = userService.selectedVehicle.variantName;
    this.VehicleChassisNo = userService.selectedVehicle.chassisNo;
    this.VehicleRegistrationNo = userService.selectedVehicle.registrationNumber;

    if (userService.selectedServiceMobilityOptionAddress) {
      const transAddress1Split =
        userService.selectedServiceMobilityOptionAddress.match(/.{1,35}/g);
      for (let i = 0; i < transAddress1Split.length; i++) {
        this[`TransAddress${i + 1}`] = transAddress1Split[i];
      }
    }
    if (userService.selectedServiceMobilityDeliveryOptionAddress) {
      const transAddress2Split =
        userService.selectedServiceMobilityDeliveryOptionAddress.match(
          /.{1,35}/g
        );
      for (let i = 0; i < transAddress2Split.length; i++) {
        this[`SecondTransAddress${i + 1}`] = transAddress2Split[i];
      }
    }
  }

  AppointmentId: number;
  CustomerId: string;
  EmailAddress: string;
  LastStep: number;
  RooftopId: string;
  JobDate: string;
  Jobs: JobCode[];
  ParentAppointmentId: number;
  Language: string;
  AdvisorID: string;
  AdvisorDropOffTimeCode: string;
  TransportMethod: string;
  VehColour: string;
  VehMakeCode: string;
  VehMakeDesc: string;
  VehModelCode: string;
  VehModelDesc: string;
  VehTrim: string;
  VehVariantCode: string;
  VehVariantDesc: string;
  VehicleChassisNo: string;
  VehicleRegistrationNo: string;
  TransAddress1: string;
  TransAddress2: string;
  TransAddress3: string;
  TransAddress4: string;
  TransAddress5: string;
  SecondTransAddress1: string;
  SecondTransAddress2: string;
  SecondTransAddress3: string;
  SecondTransAddress4: string;
  SecondTransAddress5: string;
  // not mapped, do we need to map these?
  Address1: string;
  Address2: string;
  Address3: string;
  FirstName: string;
  HomeTelNo: string;
  LastRegisteredDate: string;
  MobileTelNo: string;
  PostalCode: string;
  SurName: string;
  Title: string;
  WorkTelNo: string;
}

class ConfirmBookingContract {
  constructor(userService: UserService) {
    this.AppointmentId = userService.bookingId;
    this.CustomerId = userService.customerId;
    this.ParentAppointmentId = null;
    this.RooftopId = userService.selectedDealer.RooftopId;
    this.SendConfirmationMail = userService.languageSelected.indexOf("en_za") === -1;
    this.SetAsPreferredRoofTopId = true;
    this.Language =
      userService.languageSelected === "en_ae"
        ? userService.languageSelected.substring(0, 2) + "-ZA"
        : userService.languageSelected.substring(0, 2);
    if (userService.appointmentIdToUpdate) {
      this.ParentAppointmentId = userService.appointmentIdToUpdate;

    }

  }
  AppointmentId: number;
  CustomerId: string;
  Language: string;
  ParentAppointmentId: number;
  RooftopId: string;
  SendConfirmationMail: boolean;
  SetAsPreferredRoofTopId: boolean;
}
